.react-calendar {
    width: 400px;
    max-width: 100%;
    background-color: #fff;
    color: #222;
    border-radius: 8px;
    /*box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);*/
    font-family: Arial, Helvetica, sans-serif;
    line-height: 1.125em;
    float: none;
}
.react-calendar__navigation button {
    color: #eb9a48;
    min-width: 44px;
    background: none;
    font-size: 16px;
    margin-top: 8px;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
    background-color: #f0f0f0;
}
abbr[title] {
    text-decoration: none;
}
/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background: #f8f8fa;
    color: #F8B133;
    border-radius: 6px;
}
.react-calendar__tile--now {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #6f48eb;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: #6f48eb33;
    border-radius: 6px;
    font-weight: bold;
    color: #ebb748;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #f8f8fa;
}
.react-calendar__tile--active {
    background: #6f48eb;
    border-radius: 6px;
    font-weight: bold;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #8C6239;
    color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #f8f8fa;
}
.react-calendar__tile--range {
    background: #f8f8fa;
    color: #F8B133;
    border-radius: 0;
}
.react-calendar__tile--rangeStart {
    border-radius: 6px 0 0 6px;
    background: #6f48eb;
    color: white;
}
.react-calendar__tile--rangeEnd {
    border-radius: 0 6px 6px 0;
    background: #F8B133;
    color: white;
}